import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent, EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { Editor } from 'tinymce';
import { TinyEditorOverlayDirective } from '../../directives/tiny-editor-overlay.directive';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-tinymce-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EditorModule,
    TinyEditorOverlayDirective,
  ],
  templateUrl: './tinymce-editor.component.html',
  styleUrl: './tinymce-editor.component.scss',
  providers: [ { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' } ]
})
export class TinymceEditorComponent {

  @Input({required: true}) FC: FormControl<any> | null | undefined = null;
  @Input({required: true}) editorSettings = {};
  @Input() overlay: OverlayPanel | undefined;

  @Output() init = new EventEmitter<{ editor: Editor, event: any}>();

  @ViewChild('editor') editor: EditorComponent | undefined;
  @ViewChild('editor', { read: TinyEditorOverlayDirective }) editorOverlay: TinyEditorOverlayDirective | undefined;

  // Editor pri inicializaci triggerne FC change...
  // editorConfig = {
  //   // ... your TinyMCE config
  //   setup: (editor: Editor) => {
  //     editor.on('init', () => {
  //       editor.setContent(this.FC?.value || '', { triggerEvents: false });
  //     });
  //     editor.on('blur', () => this.FC?.markAllAsTouched());
  //     editor.on('input change', () => {
  //       const content = editor.getContent();
  //       this.FC?.setValue(content, { emitEvent: true });
  //       this.FC?.markAllAsTouched();
  //       this.FC?.updateValueAndValidity();
  //     });
  //   },
  //   entity_encoding: 'raw' as any,
  // };


  inited(e: { editor: Editor, event: any}) {
    this.init.emit(e);
  }

}
