import { Injectable } from '@angular/core';
import { CallableNames, DbService } from '../../db.service';
import { Observable } from 'rxjs';
import { ISessionAttendanceFE, SessionAttendanceState, SessionAttendanceType } from 'src/app/shared/models/session-attendance/session-attendance.model';
import { INote } from 'src/app/shared/models/_other/note.model';
import { IOrgUser } from 'src/app/shared/models/user/user.model';

interface ISessionAttendanceGetBySessionData {
  sessionUuid: string;
}

export interface ISessionAttendanceChangeStateData {
  sessionId: number;

  data: {
    userId: number;
    sessionAttendanceState: SessionAttendanceState | null;
    sessionAttendanceExcused: boolean | null;
    sessionAttendanceType?: SessionAttendanceType;
    note?: string;
  }[];
}

export interface ISessionAttendanceReplaceData {
  sessionId: number;
  userId: number;
}

interface ISessionAttendancesGetForAdminShopItemData {
  shopItemId: number;
}
export interface IShopItemAttendanceFE {
  sessions: {
    id: number;
    uuid: string;
    startAt: Date;
    endAt: Date;
    presentCnt: number;
  }[];
  participants: ISessionParticipant[];
}
interface ISessionParticipant {
  id: number;
  name: string | null;
  surname: string | null;
  orgUsers: IOrgUser[];
  email: string | null;
  presentCnt: number;
  absentCnt: number;
  sessionAttendances: IParticipantShopItemAttendance[];
}
type IParticipantShopItemAttendance = ({
  sessionId: number;
  id: number;
  state: SessionAttendanceState | null;
  excused: boolean;
  replaced: boolean;
  type: SessionAttendanceType;
  notes: INote[];
  noAttendanceYet: false;
} | {
  sessionId: number;
  noAttendanceYet: true;
});

interface ICallablesSessionAttendanceGetPdfData {
  sessionId: number;
}

@Injectable({
  providedIn: 'root'
})
export class SessionAttendancesService {

  constructor(
    private dbService: DbService,
  ) { }

  public getPdf(data: ICallablesSessionAttendanceGetPdfData): Observable<string> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionAttendanceGetPdf, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public getForAdminShopItem(data: ISessionAttendancesGetForAdminShopItemData): Observable<IShopItemAttendanceFE> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionAttendancesGetForAdminShopItem, data });
    return this.dbService.handleObs(obs, { succSilent: true });
  }

  public replace(data: ISessionAttendanceReplaceData) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionAttendanceReplace, data });
    return this.dbService.handleObs(obs);
  }

  public getBySession(data: ISessionAttendanceGetBySessionData): Observable<ISessionAttendanceFE> {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionAttendancesGetBySession, data });
    return this.dbService.handleObs(obs, { succSilent: true});
  }

  public changeStates(data: ISessionAttendanceChangeStateData, options?: { succSilent?: boolean }) {
    const obs = this.dbService.runCallable({ name: CallableNames.callablesSessionAttendanceChangeState, data });
    return this.dbService.handleObs(obs, options);
  }
}
